<template>
  <div ref="pdf">
    <h1 class="text-center mb-5">{{ $t('thisIsYourTicket') }}</h1>
    <h1 class="text-center mb-5" style="background-color: white; padding: 5px; border-style: solid;
    border-width: medium; border-color: black"> {{this.$store.getters.pvrNumber}}</h1>
    <p v-if="this.$store.getters.purchased" class="text-center mb-2">
      {{ $t('pvrTicket.yourPvrTicket') }}
    </p>
    <p v-if="this.$store.getters.accessToken !== null && this.$store.getters.accessToken !== '' && this.$store.getters.purchased" class="text-center mb-2">
      {{ $t('pvrTicket.sendMailWithInvoice') }}
    </p>
    <h3 class="text-center mb-5">
      {{ $t('pvrTicket.theCodeWillBeExpired') }}
    </h3>
    <p class="text-center mb-5">
      {{ $t('pvrTicket.enterCodeInTheFollowingMenu') }}
    </p>
    <b-row class="justify-content-center">
      <b-card-group class="justify-content-center" deck>
        <b-col class="mt-3 mb-3" cols="12" sm="12" md="6">
          <b-card
              style="max-width: 40rem; margin-left: auto; margin-right: auto"
          >
            <b-card-title class="ubuntu">Step 1</b-card-title>
            <b-card-text v-if="this.$route.query.boxName === 'Q90'" class="sansSerif text-left">
              {{ $t('pvrText1') }}
            </b-card-text>
            <b-card-text v-else-if="this.$route.query.boxName === 'Ti9' || this.$route.query.boxName === 'Microsat'" class="sansSerif text-left">
              {{ $t('pvrText1') }}
            </b-card-text>
              <b-card-text v-else-if="this.$route.query.boxName === 'Q80'" class="sansSerif text-left">
                  {{ $t('pvrText1Q80') }}
              </b-card-text>
            <b-card-img v-if="this.$i18n.locale === 'en' && this.$route.query.boxName === 'Q90'"
                        :src="require('../assets/1-Main-Menu.png')" alt="Step 1" top></b-card-img>
            <b-card-img v-else-if="this.$i18n.locale !== 'en' && this.$route.query.boxName === 'Q90'"
                        :src="require('../assets/1-Menu-Principale.jpg')" alt="Step 1" top></b-card-img>
            <b-card-img v-else-if="this.$i18n.locale === 'en' && (this.$route.query.boxName === 'Ti9' || this.$route.query.boxName === 'Microsat')"
                        :src="require('../assets/1-Main-Menu.png')" alt="Step 1" top></b-card-img>
            <b-card-img v-else-if="this.$i18n.locale !== 'en' && (this.$route.query.boxName === 'Ti9' || this.$route.query.boxName === 'Microsat')"
                        :src="require('../assets/1-Menu-Principale.jpg')" alt="Step 1" top></b-card-img>
              <b-card-img v-else-if="this.$i18n.locale === 'en' && this.$route.query.boxName === 'Q80'"
                          :src="require('../assets/1MenuActivate.png')" alt="Step 1" top></b-card-img>
              <b-card-img v-else-if="this.$i18n.locale !== 'en' && this.$route.query.boxName === 'Q80'"
                          :src="require('../assets/1MenuActivateIT.png')" alt="Step 1" top></b-card-img>
          </b-card>
        </b-col>
        <b-col class="mt-3 mb-3" cols="12" sm="12" md="6">
          <b-card
              style="max-width: 40rem; margin-left: auto; margin-right: auto"
          >
            <b-card-title class="ubuntu">Step 2</b-card-title>
            <b-card-text v-if="this.$route.query.boxName === 'Q90'" class="sansSerif text-left">
              {{ $t('pvrText2') }}
            </b-card-text>
            <b-card-text v-else-if="this.$route.query.boxName === 'Ti9' || this.$route.query.boxName === 'Microsat'" class="sansSerif text-left">
              {{ $t('pvrText2') }}
            </b-card-text>
              <b-card-text v-else-if="this.$route.query.boxName === 'Q80'" class="sansSerif text-left">
                  {{ $t('pvrText2Q80') }}
              </b-card-text>
            <b-card-img v-if="this.$i18n.locale === 'en' && this.$route.query.boxName === 'Q90'"
                        :src="require('../assets/2-USB.png')" alt="Step 2" top></b-card-img>
            <b-card-img v-else-if="this.$i18n.locale !== 'en' && this.$route.query.boxName === 'Q90'"
                        :src="require('../assets/2usbit.png')" alt="Step 2" top></b-card-img>
            <b-card-img v-else-if="this.$i18n.locale === 'en' && (this.$route.query.boxName === 'Ti9' || this.$route.query.boxName === 'Microsat')"
                        :src="require('../assets/2-USB.png')" alt="Step 2" top></b-card-img>
            <b-card-img v-else-if="this.$i18n.locale !== 'en' && (this.$route.query.boxName === 'Ti9' || this.$route.query.boxName === 'Microsat')"
                        :src="require('../assets/2usbit.png')" alt="Step 2" top></b-card-img>
              <b-card-img v-else-if="this.$i18n.locale === 'en' && this.$route.query.boxName === 'Q80'"
                          :src="require('../assets/2PVRActivate.png')" alt="Step 2" top></b-card-img>
              <b-card-img v-else-if="this.$i18n.locale !== 'en' && this.$route.query.boxName === 'Q80'"
                          :src="require('../assets/2PVRActivateIT.png')" alt="Step 2" top></b-card-img>
          </b-card>
        </b-col>
        <b-col class="mt-3 mb-3" cols="12" sm="12" md="6">
          <b-card
              style="max-width: 40rem; margin-left: auto; margin-right: auto"
          >
            <b-card-title class="ubuntu">Step 3</b-card-title>
            <b-card-text v-if="this.$route.query.boxName === 'Q90'" class="sansSerif text-left">
              {{ $t('pvrText3') }}
            </b-card-text>
            <b-card-text v-else-if="this.$route.query.boxName === 'Ti9' || this.$route.query.boxName === 'Microsat'" class="sansSerif text-left">
              {{ $t('pvrText3') }}
            </b-card-text>
              <b-card-text v-else-if="this.$route.query.boxName === 'Q80'" class="sansSerif text-left">
                  {{ $t('pvrText3Q80') }}
              </b-card-text>
            <b-card-img v-if="this.$i18n.locale === 'en' && this.$route.query.boxName === 'Q90'"
                        :src="require('../assets/3-Enter-key.png')" alt="Step 3" top></b-card-img>
            <b-card-img v-else-if="this.$i18n.locale !== 'en' && this.$route.query.boxName === 'Q90'"
                        :src="require('../assets/3-Inserisci-codice.png')" alt="Step 3" top></b-card-img>
            <b-card-img v-else-if="this.$i18n.locale === 'en' && (this.$route.query.boxName === 'Ti9' || this.$route.query.boxName === 'Microsat')"
                        :src="require('../assets/3-Enter-key.png')" alt="Step 3" top></b-card-img>
            <b-card-img v-else-if="this.$i18n.locale !== 'en' && (this.$route.query.boxName === 'Ti9' || this.$route.query.boxName === 'Microsat')"
                        :src="require('../assets/3-Inserisci-codice.png')" alt="Step 3" top></b-card-img>
              <b-card-img v-else-if="this.$i18n.locale === 'en' && this.$route.query.boxName === 'Q80'"
                          :src="require('../assets/3PVRActivation.png')" alt="Step 3" top></b-card-img>
              <b-card-img v-else-if="this.$i18n.locale !== 'en' && this.$route.query.boxName === 'Q80'"
                          :src="require('../assets/3PVRActivationIT.png')" alt="Step 3" top></b-card-img>
          </b-card>
        </b-col>
        <b-col v-if="this.$route.query.boxName !== 'Q80'" class="mt-3 mb-3" cols="12" sm="12" md="6">
          <b-card
              style="max-width: 40rem; margin-left: auto; margin-right: auto"
          >
            <b-card-title class="ubuntu">Step 4</b-card-title>
            <b-card-text v-if="this.$route.query.boxName === 'Q90'" class="sansSerif text-left">
              {{ $t('pvrText4') }}
            </b-card-text>
            <b-card-text v-else-if="this.$route.query.boxName === 'Ti9' || this.$route.query.boxName === 'Microsat'" class="sansSerif text-left">
              {{ $t('pvrText4') }}
            </b-card-text>
            <b-card-img v-if="this.$i18n.locale === 'en' && this.$route.query.boxName === 'Q90'"
                        :src="require('../assets/4-PVR-activated.png')" alt="Step 4" top></b-card-img>
            <b-card-img v-else-if="this.$i18n.locale !== 'en' && this.$route.query.boxName === 'Q90'"
                        :src="require('../assets/4-PVR-Attivato.png')" alt="Step 4" top></b-card-img>
            <b-card-img v-else-if="this.$i18n.locale === 'en' && (this.$route.query.boxName === 'Ti9' || this.$route.query.boxName === 'Microsat')"
                        :src="require('../assets/4-PVR-activated.png')" alt="Step 4" top></b-card-img>
            <b-card-img v-else-if="this.$i18n.locale !== 'en' && (this.$route.query.boxName === 'Ti9' || this.$route.query.boxName === 'Microsat')"
                        :src="require('../assets/4-PVR-Attivato.png')" alt="Step 4" top></b-card-img>
          </b-card>
        </b-col>
      </b-card-group>
    </b-row>
    <div>
      <b-row class="my-5" align-h="between">
        <b-col class="justify-content-start align-items-start text-center" cols="12">
          <b-button v-if="this.$store.getters.purchased && (!this.downloaded && this.$store.getters.accessToken === null || this.$store.getters.accessToken !== null)" @click="createPDF" class="btn sansSerif mr-auto" style="background-color: #0000FF; border-radius: 0!important;
         color: white">
            {{ $t('pvrTicket.downloadInvoice') }}
          </b-button>
        </b-col>
        <b-col class="justify-content-end align-items-end text-end" cols="12">
          <router-link class="btn sansSerif ml-auto" style="background-color: #000000; border-radius: 0!important;
         color: white" :to="{ name: 'home'}" >
            {{ $t('macMenu.backToBoxSelection') }}
          </router-link>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TransactionDataService from "../services/TransactionDataService";
import download from 'downloadjs';

export default {
  name: "pvrTicket",
  data() {
    return {
      macAddress: '',
      macAddressConfirm: '',
      downloaded: false,
      transaction: {
        id: null,
        userId: null,
        boxTypeId: this.id,
        macAddress: '',
        pvrNumber: '',
      },
      pvrNumber: this.$store.getters.pvrNumber,
      submitted: false,
      error: false,
      errorMsg: 'The entered mac address is not correct, please check the entry again',
      items: [
        {
          text: 'Home',
          href: '#/home'
        },
        {
          text: 'Q20',
          active: true
        },
      ]
    }
  },
  computed: {
    macAddressState() {
      let testString = this.macAddress.replace(/[^:]/g, "").length;
      if(this.macAddress === "") {
        if(this.macAddress === '' && this.submitted){
          return false;
        }
        return null;
      }
      if(this.macAddress.length < 17 || this.macAddress.length > 17 || testString.length < 5 || this.macAddress === '' && this.submitted){
        return false;
      } else{
        return true;
      }
    },
    macAddressConfirmState() {
      if(this.macAddress === '' || this.macAddressConfirm === ''){
        return null;
      } else if(this.macAddress !== this.macAddressConfirm){
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    createPDF() {
      window.open()
      console.log("Da");
      this.downloaded = true;
      let params = {
        id: this.$store.getters.transactionId,
        filename:  this.$store.getters.filename,
        responseType: 'blob',
        'headers': { 'x-access-token': this.$store.getters.accessToken },
      }
      console.log("PDF PARAMS!");
      console.log(params);
      TransactionDataService.generateInvoice(params)
          .then(response => {
            console.log(response);
            console.log(this.$store.getters.transactionId);
            const content = response.headers['content-type'];
            download(response.data, this.$store.getters.filename + '_' + this.$store.getters.transactionId + '.pdf', content);
          })
          .catch(e => {
            console.log(e);
          });
    },

    formateDate2(date) {
      let dateMonth = '';
      let day = date.getDate();
      if (date.getMonth() + 1 < 10) {
        dateMonth = '0' + (date.getMonth() + 1);
      } else {
        dateMonth = (date.getMonth() + 1);
      }
      if (date.getDate() < 10) {
        day = '0' + date.getDate();
      }
      let year = date.getFullYear() + '';
      return day + '_' + dateMonth + '_' + year[2] + year[3];
    },

    saveTransaction() {
      let testString = this.macAddress.replace(/[^:]/g, "").length;
      console.log(testString);
      if(this.macAddress.length < 17 || this.macAddress.length > 17 || testString.length < 5){
        this.error = true;
      } else{
        this.error = false;
        let data = {
          userId: 14,
          boxTypeId: this.id,
          macAddress: this.macAddress,
        };
        console.log(this.macAddress);
        console.log(data);
      }
    },
    next(){
      if(this.macAddressState && this.macAddressConfirmState){
        this.$store.commit('SET_MAC_ADDRESS', {macAddress: this.macAddress});
        this.$store.commit('SET_BOX_TYPE', {boxType: '1'});
        this.$router.push({name: 'orderOptions'});
      }else{
        this.submitted = true;
      }
    },
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Ubuntu&display=swap');
.ubuntu {
  font-family: 'Ubuntu', sans-serif!important;
}
.sansSerif{
  font-family: 'Open Sans', sans-serif!important;
}
</style>
